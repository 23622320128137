import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import { Card, CardHeader, CardBody, CardText } from 'reactstrap';


const RedisStatus = (props) => {
  let usedMemory = null;
  let maxMemory = null;
  if(props.redis_info){
    usedMemory = props.redis_info.used_memory_human || null;
    maxMemory = props.redis_info.max_memory_human || 100;
  }

  let usedMemoryFloat = 0;
  if(usedMemory){
    usedMemoryFloat = parseFloat(usedMemory);
  }

  const data = {
    labels: [
      'Used',
      'Available',
    ],

    datasets: [{
      data: [usedMemoryFloat, maxMemory-usedMemoryFloat],
      backgroundColor: [
        '#FF6384',
        '#36A2EB',
      ],
      hoverBackgroundColor: [
        '#FF6384',
        '#36A2EB',
      ]
    }]
  };

  return (
    <Card>
      <CardHeader className={'card-title'}>Redis Status</CardHeader>
      <CardBody>
        <CardText>Used Memory: {usedMemory}/{maxMemory}M</CardText>
        {/*<CardText>Percent Used: {Number(percentUsed).toFixed(2)}%</CardText>*/}
        <Doughnut data={data} />
      </CardBody>
    </Card>
  );
};

export default RedisStatus;
