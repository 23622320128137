import React from 'react';

import { Card, CardHeader, CardBody, CardText } from 'reactstrap';


const StaleQuery = (props) => {
  let ignoredIds = [];
  if(props.stale){
    ignoredIds = props.stale.map(record => record.source_id)
  }

  const date = new Date();
  date.setHours(date.getHours() - 3);
  const query = `SELECT id, Title__c, Type__c, Listing__c, Listing__r.External_Listing_Id__c, Listing__r.Name, Listing__r.RecordTypeId, SystemModStamp 
                 FROM Content__c 
                 WHERE SystemModStamp <= ${date.toISOString()} AND Listing__r.RecordTypeId != '0123A000001GhkZQAS' 
                 AND Listing__r.External_Listing_Id__c NOT IN ('${ignoredIds.join("','")}') 
                 ORDER BY Type__c
                 `;



  return (
    <Card>
      <CardHeader className={'card-title'}>Stale Content Query</CardHeader>
      <CardBody>
        <CardText id={"query"}><pre><code>{query}</code></pre></CardText>
      </CardBody>
    </Card>
  )
};

export default StaleQuery;
