import React from 'react';

import { Card, CardHeader, CardBody, CardText } from 'reactstrap';


const ObjectStatus = (props) => {
  let counts = {
    success: null,
    invalid: null,
    pending: null,
    failed: null
  };
  if(props.healthz){
    counts = props.healthz;
  }
  return (
    <Card>
      <CardHeader className={'card-title'}>Object Status</CardHeader>
      <CardBody>
        <CardText>Successful: {counts.success}</CardText>
        <CardText>Pending: {counts.pending}</CardText>
        <CardText>Invalid: {counts.invalid}</CardText>
        <CardText>Failed: {counts.failed}</CardText>
      </CardBody>
    </Card>
  );
};

export default ObjectStatus;
