import React, {useState} from 'react';
import { TabContent, Nav, NavItem, NavLink, Alert } from 'reactstrap';
import classnames from 'classnames';

import TabView from './TabView';

const ListingDetail = (props) => {
  const [activeTab, setActiveTab] = useState('2');
  if(props.creds.username === ''){
    return (
      <Alert color="danger">
        'NOT LOGGED IN'
      </Alert>
    );
  }

  if(props.data.length === 0){
    return (
      <em> No Records Found </em>
    );
  }
  const listing = props.data[0];

  const tabNames = [
    {
      name: 'Account',
      data: listing.Account,
    },
    {
      name: 'Publisher',
      data: listing.Publisher,
    },
    {
      name: 'Listing',
      data: listing.Listing,
    },
    {
      name: 'Subscription Plans',
      data: listing.SubscriptionPlans,
    },
    {
      name: 'Partner Locations',
      data: listing.PartnerLocations,
    },
    {
      name: 'Apps',
      data: listing.Apps,
    },
    {
      name: 'Contents',
      data: listing.Contents,
    },
    {
      name: 'Publisher Taxes',
      data: listing.PublisherTaxes,
    }
  ];

  const tabs = tabNames.map((obj, index) =>
    <NavItem key={index}>
      <NavLink
        className={classnames({ active: activeTab === index.toString() })}
        onClick={() => { toggle(index.toString()); }}
      >
        {obj.name}
      </NavLink>
    </NavItem>
  );

  const infoTabs = tabNames.map((obj, index) =>
    <TabView key={index} type={obj.name} tabId={index} data={obj.data}/>
  );

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <Nav tabs>
        {tabs}
      </Nav>
      <TabContent activeTab={activeTab}>
        {infoTabs}
      </TabContent>
    </div>
  );
};

export default ListingDetail;
