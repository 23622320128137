import React from 'react';
import Moment from 'react-moment';

import { Card, CardHeader, CardBody, Table } from 'reactstrap';

const TimeLog = (props) => {
  // timeMap = { Tz: {update: <date>, delete: <date>}}
  let timeMap = {};
  if(props.healthz.last_successful_update){
    for(let arr in props.healthz.last_successful_update){
      timeMap[props.healthz.last_successful_update[arr][0]] = {
        update: props.healthz.last_successful_update[arr][1],
        delete: null
      };
    }
  }
  if(props.healthz.last_successful_delete){
    for(let arr in props.healthz.last_successful_delete){
      if(timeMap[props.healthz.last_successful_delete[arr][0]]){
        timeMap[props.healthz.last_successful_delete[arr][0]].delete =
          props.healthz.last_successful_delete[arr][1];
      } else {
        timeMap[props.healthz.last_successful_delete[arr][0]] =
          {
            update: null,
            delete: props.healthz.last_successful_delete[arr][1],
          }
      }

    }
  }

  return (
    <Card>
      <CardHeader className={'card-title'}>Time Log</CardHeader>
      <CardBody>
        <Table responsive striped>
          <thead>
          <tr>
            <th>Provider Name</th>
            <th>Last Update</th>
            <th>Last Delete</th>
          </tr>
          </thead>
          <tbody>
          {
            Object.keys(timeMap).map(function (name, index) {
              let lastUpdate = getTimeStampFromHoursPast(timeMap[name].update);
              let lastDelete = getTimeStampFromHoursPast(timeMap[name].delete);

              let lastUpdateMarkup = lastUpdate ? (<Moment fromNow>{lastUpdate}</Moment>) : null;
              let lastDeleteMarkup = lastDelete ? (<Moment fromNow>{lastDelete}</Moment>) : null;

              return (
                <tr key={index}>
                  <th scope="row">{name}</th>
                  <td>{lastUpdateMarkup}</td>
                  <td>{lastDeleteMarkup}</td>
                </tr>
              );
            })
          }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

// Takes an float `hours` and subtracts that from current time
const getTimeStampFromHoursPast = (hours) => {
  if(hours){
    const millisecondsPast = ((hours*3600)*1000);
    const now = new Date();
    now.setMilliseconds(now.getMilliseconds()-millisecondsPast);
    return now;
  } else {
    return null;
  }

};

export default TimeLog;
