import React, { useState } from 'react';
import {useParams} from 'react-router-dom';

import Form from './lookup/Form';
import ListingDetail from './lookup/ListingDetail';
import Loader from './Loader';


const Lookup = (props) => {
  let {recordId} = useParams();
  let [paramId, setParamId] = useState(recordId);
  const [records, setRecords] = useState([]);

  const clearParamId = () => setParamId(null);
  const updateRecords = (newData) => {
    setRecords(newData);
    props.updateLoading(false);
  };

  if(props.loading){
    return (
      <div className="App">
        <Form updateHandler={updateRecords} creds={props.creds} env={props.env} paramId={paramId} clearParamId={clearParamId} updateLoading={props.updateLoading}/>
        <Loader loading={props.loading}/>
      </div>
    );
  }
  return (
    <div className="App">
      <Form updateHandler={updateRecords} creds={props.creds} env={props.env} paramId={paramId} clearParamId={clearParamId} updateLoading={props.updateLoading}/>
      <ListingDetail data={records} env={props.env} creds={props.creds}/>
    </div>
  );
};

export default Lookup;
