import React from 'react';
import Moment from 'react-moment';

import { Card, CardHeader, CardBody, Table } from 'reactstrap';

const ErrorList = (props) => {
  let errant = [];
  if(props.records){
    errant = props.records;
  }
  return (
    <Card>
      <CardHeader className={'card-title'}>{props.status} Records</CardHeader>
      <CardBody>
        <Table responsive striped>
          <thead>
          <tr>
            <th>Record Type</th>
            <th>Source ID</th>
            <th>AppExchange ID</th>
            <th>Errors</th>
            <th>Last Attempt</th>
            <th>Last Success</th>
          </tr>
          </thead>
          <tbody>
          {
            errant.map(function (record, index) {
              let lastUpdate = new Date(parseInt(record.last_update));
              let lastSuccess = new Date(parseInt(record.last_success));
              let listingId = getSourceId(record);
              let sourceIdElem = listingId ? (<a href={`/lookup/${listingId}`}>{record.source_id}</a>) : record.source_id;
              return (
                <tr key={index}>
                  <th>{record.type}</th>
                  <td>{sourceIdElem}</td>
                  <td>{record.destination_id}</td>
                  <td>{record.errors}</td>
                  <td><Moment fromNow>{lastUpdate}</Moment></td>
                  <td><Moment fromNow>{lastSuccess}</Moment></td>
                </tr>
              );
            })
          }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

function getSourceId(record) {
  switch(record.type){
    case 'Account':
      return null;
    case 'Publisher__c':
      return null;
    case 'Listing__c':
      return record.source_id;
    default:
      return record.child_of;
  }
}

export default ErrorList;
