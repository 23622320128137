import React from 'react';
import _ from 'lodash';

import { Card, Button, CardHeader, CardBody, CardText } from 'reactstrap';


const Overview = (props) => {
  const healthzURL = `https://${props.name}.herokuapp.com/healthz`;

  let healthEmoji = null;
  if(props.healthz.healthy != null){
    healthEmoji = props.healthz.healthy ? '✅' : '❌';
  }

  let workerState = (_.isEmpty(props.state)) ? 'unknown' : 'off';

  if(props.state.worker){
    workerState = `${props.state.worker.state}`;
  }

  return (
    <Card>
      <CardHeader className={'card-title'}>{props.name}</CardHeader>
      <CardBody>
        <CardText>Status: {workerState}</CardText>
        <CardText>Healthy: {`${healthEmoji}`}</CardText>
        <Button color={props.healthz.healthy ? 'success' : 'danger'} onClick={ () => {window.open(healthzURL, "_blank")}}>Healthz Page</Button>
      </CardBody>
    </Card>
  );
};

export default Overview;
