import RiseLoader from "react-spinners/RiseLoader";
import React from "react";

const Loader = (props) => {
  return (
    <div className="loader">
      <RiseLoader
        size={15}
        color={"red"}
        loading={props.loading}
      />
    </div>
  );
};

export default Loader;
