import React, {useState} from 'react';
import {Button} from 'reactstrap';
import axios from 'axios';

const Form = (props) => {
  const [listingId, setListingId] = useState(props.paramId || '');

  const makeRequest = async (listingId) => {
    try{
      const resp = await axios.get(`/api/${listingId}`, {auth: props.creds, params: {env:props.env}});
      console.log(resp);
      props.updateHandler(resp.data);
    } catch (e) {
      console.log('Error retrieving listing information for input:', listingId);
      console.log(e);
      props.updateHandler([]);
    }
    props.updateLoading(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    props.updateLoading(true);
    await makeRequest(listingId);
  };

  // If we have a param Id, show that listing, then clear it.
  if(props.paramId){
    makeRequest(props.paramId);
    props.clearParamId();
  }

  if(listingId === ''){
    props.updateLoading(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <input
        type='text'
        value={listingId}
        onChange={event => setListingId(event.target.value)}
        placeholder='External Listing Id'
        required
      />
      <Button color='success'>Find Listing</Button>
    </form>
  );
};

export default Form;
