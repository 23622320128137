import {Col, Row, TabPane, Table} from "reactstrap";
import React from "react";
import Moment from "react-moment";

const TabView = (props) => {
  const columns = [
    'source_id',
    'destination_id',
    'status',
    'last_update',
    'last_success',
    'child_of',
    'errors',
    'method'
  ];

  const headings = columns.map((name, index) =>
    <th key={index}>{name}</th>
  );

  const rows = props.data.map((data, index) => {
    let rows = columns.map((name, colIndex) => {
        if (name.includes('last') && data[name]) {
          let timestamp = new Date(parseInt(data[name]));
          let timeElem = timestamp ? (<Moment fromNow>{timestamp}</Moment>) : null;
          return (<td key={colIndex}>{timeElem}</td>);
        }
        return (<td key={colIndex}>{data[name]}</td>);
      }
    );
    return <tr key={index}>{rows}</tr>;
  });

  return (
    <TabPane tabId={props.tabId.toString()}>
      <Row>
        <Col sm="12">
          <h4>{props.type} ({rows.length})</h4>
        </Col>
      </Row>
      <Table>
        <thead>
          <tr>
            {headings}
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    </TabPane>
  );
};

export default TabView;
