import React, {useState} from 'react';
import { Router, Route, Redirect} from 'react-router-dom';

import createPersistedState from 'use-persisted-state';

import Lookup from './Lookup';
import State from './State';
import NavBar from './NavBar';
import Troubleshooting from './Troubleshooting';

import './stylesheets/App.css';

import history from '../store/history';

const envs = [
  'dev',
  'qa',
  'uat',
  'intel-stg',
  'stg',
  'standby',
  'prod'
];

const useCredsState = createPersistedState('creds');
const useEnvState = createPersistedState('env');

const App = (props) => {
  const [env, setEnv] = useEnvState('prod');
  const [creds, setCreds] = useCredsState({username: '', password: ''});
  const [loading, setLoading] = useState(true);

  const updateEnv = (newEnv) => {
    setEnv(newEnv);
    updateLoading(true);
  };
  const updateCreds = (newCreds) => setCreds(newCreds);
  const updateLoading = (val) => setLoading(val);

  return (
    <Router history={history}>
      <NavBar envHandler={updateEnv} env={env} envChoices={envs} creds={creds} credsHandler={updateCreds}/>
      <Route exact path="/lookup" render={(props) => <Lookup {...props} creds={creds} env={env} loading={loading} updateLoading={updateLoading}/>} />
      <Route exact path="/lookup/:recordId" render={(props) => <Lookup {...props} creds={creds} env={env} loading={loading} updateLoading={updateLoading}/>} />
      <Route exact path="/state" render={(props) => <State {...props} creds={creds} env={env} loading={loading} updateLoading={updateLoading}/>} />
      <Route exact path="/troubleshooting" render={(props) => <Troubleshooting {...props} creds={creds} env={env} loading={loading} updateLoading={updateLoading} />} />
      <Route exact path="/" render={()=>(<Redirect to="/state" />)} />
    </Router>
  );
};

export default App;
