import React, {useEffect, useState} from 'react';

import { Row, Col, Alert } from 'reactstrap';

import Overview from './state/Overview';
import ObjectStatus from "./state/ObjectStatus";
import RedisStatus from "./state/RedisStatus";
import TimeLog from './state/TimeLog.jsx';
import Loader from './Loader';

import axios from "axios";
import ErrorList from "./state/ErrorList";
import StaleQuery from "./state/StaleQuery";

const State = (props) => {
  const name = `indexer-${props.env}`;

  const healthzURL = `/status/${props.env}`;

  const [healthz, setHealthz] = useState({});
  const updateHealthz = (healthzObj) => {
    setHealthz(healthzObj);
  };

  const [state, setState] = useState({});
  const updateState = (stateObj) => {
    setState(stateObj);
  };

  useEffect( () => {
    async function fetchHealthz() {
      try {
        const resp = await axios.get(healthzURL);
        if(!resp.data.healthz.error){
          updateHealthz(resp.data.healthz);
        } else {
          updateHealthz({error: 'ERROR RETRIEVING HEALTHZ INFORMATION'});
        }
        if(!resp.data.state.error){
          updateState(resp.data.state);
        } else {
          updateState({error: 'ERROR RETRIEVING STATE INFORMATION'});
        }
      } catch (e) {
        console.error(e);
        updateHealthz({error: e});
        updateState({error: e});
      }
      props.updateLoading(false);
    }
    fetchHealthz();
  }, [healthzURL]);

  if(healthz.error){
    return (
      <Alert color="danger">
        Unable to request healthz information
      </Alert>
    );
  }

  if(props.loading){
    return (
      <Loader loading={props.loading}/>
    );
  } else {
    return (
      <div className={'state-body'}>
        <Row>
          <Col med="3">
            <Overview className='card' name={name} healthz={healthz} state={state}/>
          </Col>
          <Col med="3">
            <ObjectStatus healthz={healthz.object_counts}/>
          </Col>
          <Col med="3">
            <RedisStatus redis_info={healthz.redis_info}/>
          </Col>
        </Row>
        <Row>
          <Col className='time-log' med="6">
            <TimeLog className='card' healthz={healthz}/>
          </Col>
        </Row>
        <Row>
          <Col med="6">
            <ErrorList className='card' status='Invalid' records={(healthz.object_status) ? healthz.object_status.invalid :  []}/>
          </Col>
        </Row>
        <Row>
          <Col med="6">
            <ErrorList className='card' status='Failed' records={(healthz.object_status) ? healthz.object_status.failed :  []}/>
          </Col>
        </Row>
        <Row>
          <Col med="6">
            <StaleQuery className='card' stale={(healthz.object_status) ? healthz.object_status.invalid.concat(healthz.object_status.failed) :  []}/>
          </Col>
        </Row>
      </div>

    );
  }
};

export default State;
