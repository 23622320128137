import React, { useState } from 'react';
import {
  Button,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import AuthModal from "./AuthModal";

const NavBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const envChoices = props.envChoices.map((choice, index) => {
    return (
      <DropdownItem key={index}>
        {choice}
      </DropdownItem>
    );
  });

  const onEnvChange = (e) => {
    e.preventDefault();
    props.envHandler(e.target.innerHTML);
  };

  let button = undefined;
  if (!props.creds.username) {
    button = <AuthModal buttonLabel='Login!' updateCreds={props.credsHandler}/>;
  } else {
    button = <Button className="login-button" color="success" onClick={()=>props.credsHandler({username: '', password: ''})}>Logout</Button>
  }

  return (
    <div>
      <Navbar color="light" light expand="md">
        <NavbarBrand href="/">Indexing Service Browser</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="/state">State</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/lookup">Lookup</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/troubleshooting">Troubleshooting</NavLink>
            </NavItem>
          </Nav>
          {button}
          <UncontrolledDropdown>
            <DropdownToggle caret>
              {props.env}
            </DropdownToggle>
            <DropdownMenu onClick={onEnvChange}>
              {envChoices}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default NavBar;
