import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';

import axios from 'axios';

const AuthModal = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const usernameHandler = (event) => setUsername(event.target.value);
  const passwordHandler = (event) => setPassword(event.target.value);

  const submitHandler = async (event) => {
   if(username && password){
      try {
        const auth = {
          username: username,
          password: password
        };
        const resp = await axios.get('/api/test', {auth:auth});
        if(resp.status === 200){
          console.log('Successful Auth!');
          props.updateCreds(auth);
          toggle();
        }
      } catch (e){
        console.log('Unsuccessful Auth!');
        console.log(e);
        setUsername('');
        setPassword('');
        alert('Incorrect Credentials');
      }
    }
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button className='login-button' color="primary" onClick={toggle}>{buttonLabel}</Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Login</ModalHeader>
        <ModalBody>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Username</InputGroupText>
            </InputGroupAddon>
            <Input id='username' placeholder="username" onChange={usernameHandler} value={username}/>
          </InputGroup>
          <br />
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Password</InputGroupText>
            </InputGroupAddon>
            <Input id='password' type='password' placeholder="password" onChange={passwordHandler} value={password}/>
          </InputGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={submitHandler}>Login</Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AuthModal;
